/* MaintenancePage.css */
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
}

.maintenance-box {
  text-align: center;
  padding: 30px 20px;
  /* background-color: white; */
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  max-width: 500px;
  width: 90%;
  margin: auto;
}

.maintenance-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.maintenance-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.maintenance-message {
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
  font-size: 16px;
  word-break: keep-all; /* 한글 단어 분리 방지 */
}

.maintenance-apology {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 14px;
  word-break: keep-all; /* 한글 단어 분리 방지 */
}

.maintenance-time {
  font-size: 14px;
  color: #888;
  margin-top: 15px;
}

/* 모바일 화면 대응 */
@media screen and (max-width: 480px) {
  .maintenance-box {
    padding: 25px 15px;
    width: 95%;
  }

  .maintenance-title {
    font-size: 20px;
  }

  .maintenance-message {
    font-size: 14px;
  }

  .maintenance-apology {
    font-size: 13px;
  }

  .maintenance-time {
    font-size: 12px;
  }
}

/* 매우 작은 화면 대응 */
@media screen and (max-width: 320px) {
  .maintenance-box {
    padding: 20px 12px;
  }

  .maintenance-title {
    font-size: 18px;
  }
}
