.typing-indicator {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;

  .dots {
    display: flex;
    gap: 4px;

    .dot {
      width: 8px;
      height: 8px;
      background-color: #bbb;
      border-radius: 50%;
      animation: blink 1.4s infinite ease-in-out both;
    }

    .dot:nth-child(1) {
      animation-delay: 0s;
    }

    .dot:nth-child(2) {
      animation-delay: 0.2s;
    }

    .dot:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  .thinking-text {
    font-style: italic;
    color: #888;
    font-size: 14px;
    white-space: pre; // 공백 유지
  }
}

@keyframes blink {
  0%, 80%, 100% {
    opacity: 0.2;
    transform: scale(0.9);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
}